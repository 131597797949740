import { css } from '@styled-system/css';
import { Button } from 'components/buttons';
import { Input } from 'components/form/Input';
import { Label, LabelSmall } from 'components/form/Label';
import { SelectInput } from 'components/form/SelectInput';
import { ToggleButton } from 'components/form/ToggleButton';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { useAuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { languageOptions } from 'context/LocaleContext';
import { useQryGetUserMfa } from 'graphql/generated';
import { useEditUserInput } from 'graphql/mutation/useEditUserInput';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToggleMFAForUser } from 'server/mutations/useToggleMFAForUser';
import { Severity } from 'state/snackbarStore';
import styled from 'styled-components';
import { User } from 'types/user';
import { auth } from 'utils/Authentication';
import { dispatchErrors } from 'utils/util';

const phoneValidator = (phone: string): boolean => {
  const regEx = /^\+[1-9]\d{10,14}$/;
  return regEx.test(phone);
};

const Error = styled.span(
  css({
    color: 'danger',
  }),
);

type Props = DrawerComponentProps & {
  user: User;
};

export const ProfileEditDrawer: FC<Props> = ({ user, requestClose }) => {
  const { t, i18n } = useTranslation();
  useRouteChangeBlock();
  const authContext = useAuthContext();
  const [phone, setPhone] = useState<string>(user.phone ?? '');
  const [firstName, setFirstName] = useState<string>(user.firstName ?? '');
  const [lastName, setLastName] = useState<string>(user.lastName ?? '');
  const [language, setLanguage] = useState(user.language || 'en');
  const [valid, setValid] = useState<boolean>(true);
  const [userMFA, setUserMFA] = useState<boolean>(true);
  const { addAlert } = useSnackbar();
  const [{ fetching: loading }, editUserInput] = useEditUserInput();
  const [{ fetching: loadingToggleMFA }, toggleMFAForUser] = useToggleMFAForUser();
  const accessToken = auth.getAccessToken();

  const [{ data: userMFAQuery }] = useQryGetUserMfa(
    true,
    {
      userId: user.auth0Id ?? '',
      accessToken: accessToken ?? '',
    },
    {},
  );

  useEffect(() => {
    if (userMFAQuery !== undefined) {
      setUserMFA(userMFAQuery);
    }
  }, [userMFAQuery]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValid(true);
    setPhone(event.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    if (phone && !phoneValidator(phone)) {
      setValid(false);
      return;
    } else {
      const editResponse = await editUserInput({ data: { language, phone, firstName, lastName } });
      if (editResponse.data?.editUser) {
        addAlert(t('User profile updated'), Severity.SUCCESS);
      } else if (editResponse.error) {
        dispatchErrors({ addAlert }, editResponse.error, authContext, t);
      }
      requestClose();
    }
  }, [phone, editUserInput, language, firstName, lastName, requestClose, addAlert, t, authContext]);

  const handleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const onClose = () => {
    requestClose();
    i18n.changeLanguage(user.language);
  };

  const onToggleMFA = async () => {
    try {
      if (accessToken && user.auth0Id) {
        await toggleMFAForUser({ userId: user.auth0Id, enableMFA: !userMFA, accessToken });
        setUserMFA(!userMFA);
        addAlert(t('Two-factor authentication updated'), Severity.SUCCESS);
      }
    } catch (error) {
      addAlert(t('Error updating two-factor authentication'), Severity.ERROR);
      console.error('Error updating two-factor authentication:', error);
    }
  };

  return (
    <DrawerLayout title={t('actions.Edit profile')} onClose={onClose}>
      <Body>
        <Flex flexDirection="column" marginBottom="1.5rem">
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t('placeholders.Enter your first name')}
            label={t('First name')}
            width="16.5rem"
          />
        </Flex>
        <Flex flexDirection="column" marginBottom="1.5rem">
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t('placeholders.Enter your last name')}
            label={t('Last name')}
            width="16.5rem"
          />
        </Flex>
        <Flex flexDirection="column" marginBottom="1.5rem">
          <Input
            value={phone}
            onChange={onChange}
            placeholder={t('placeholders.Enter your phone number')}
            label={t('Phone number')}
            width="16.5rem"
          />
          {!valid && phone && (
            <Error>{`${t('errors.Invalid phone number')}: [+][${t('errors.country code')}][${t(
              'errors.phone number including area code',
            )}]`}</Error>
          )}
        </Flex>
        <Flex flexDirection="column">
          <SelectInput
            label={t('Language')}
            options={languageOptions}
            onChange={handleLanguage}
            value={languageOptions.find((lang) => lang.value === language)}
            placeholder={t('Select language')}
            width="16.5rem"
          />
        </Flex>
        <Flex flexDirection="column" mt="1.5rem" gap="0.25rem">
          <Label>{t('Multi factor authentication')}</Label>
          <LabelSmall>{t("Each time you login we'll send a code to your email to confirm")}</LabelSmall>
          <ToggleButton value={userMFA} onChange={onToggleMFA} />
        </Flex>
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={onClose} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button
          smallPad
          type="submit"
          disabled={loading || loadingToggleMFA}
          loading={loading || loadingToggleMFA}
          onClick={onSubmit}
        >
          {t('actions.Save')}
        </Button>
      </DrawerFooter>
    </DrawerLayout>
  );
};
