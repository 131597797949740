import { useMutation } from 'urql';

const TOGGLE_MFA_FOR_USER_KEY = 'toggleMFAForUser';

const TOGGLE_MFA_FOR_USER = `
  mutation ($userId: String!, $enableMFA: Boolean!, $accessToken: String!) {
    ${TOGGLE_MFA_FOR_USER_KEY}(userId: $userId, enableMFA: $enableMFA, accessToken: $accessToken) {
      userId
      userMetadata {
        useMfa
      }
    }
  }
`;

type MutVarToggleMFAForUser = {
  userId: string;
  enableMFA: boolean;
  accessToken: string;
};

type MutResToggleMFAForUser = {
  [TOGGLE_MFA_FOR_USER_KEY]: {
    userId: string;
    userMetadata: {
      useMfa: boolean;
    };
  };
};

export const useToggleMFAForUser = () => {
  return useMutation<MutResToggleMFAForUser, MutVarToggleMFAForUser>(TOGGLE_MFA_FOR_USER);
};
