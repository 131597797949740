/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthContextType } from 'context/AuthContext';
import type { TFunction } from 'i18next';
import { ChangeEvent } from 'react';
import { Severity, SnackbarStore } from 'state/snackbarStore';
import { CombinedError } from 'urql';

export const getURLParam = (name: string): string | undefined => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) ?? undefined;
};

export type Func<Params extends any[]> = (...args: Params) => Promise<void> | void;
export const debounce = <P extends any[]>(func: Func<P>, wait: number): ((...args: P) => void) => {
  let timer: number | undefined = undefined;
  return (...args: P) => {
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    const later = () => {
      timer = undefined;
      func(...args);
    };
    timer = window.setTimeout(later, wait);
  };
};

export function formatNumber<T extends { value: string }>(event: ChangeEvent<T>): number | undefined {
  const value = event.target.value;
  const numberValue = (value as unknown as number) * 1;
  if (value === '' || isNaN(numberValue)) {
    return undefined;
  }
  return numberValue;
}

function retry<Type>(generatePromise: () => Promise<Type>, backOffCounter: number, backOffMax: number) {
  return new Promise<Type>((resolve, reject) => {
    setTimeout(() => {
      tryPromise(generatePromise, backOffCounter * 2, backOffMax)
        .then(resolve)
        .catch(reject);
    }, backOffCounter);
  });
}

export async function tryPromise<Type>(
  generatePromise: () => Promise<Type>,
  backOffCounter = 100,
  backOffMax = 5000,
): Promise<Type> {
  try {
    const result = await generatePromise();
    return result;
  } catch (error) {
    if (backOffCounter < backOffMax) {
      return retry(generatePromise, backOffCounter, backOffMax);
    } else {
      throw error;
    }
  }
}

export function dispatchErrors(
  snackbar: Pick<SnackbarStore, 'addAlert'>,
  error: CombinedError,
  authContext: AuthContextType,
  t: TFunction,
): void {
  if (error.networkError) {
    snackbar.addAlert(t('We encountered a network error: {{data}}', { data: error.networkError.name }), Severity.ERROR);
  }
  if (error.graphQLErrors.length > 0) {
    for (const graphError of error.graphQLErrors) {
      if (graphError.message.includes('jwt expired')) {
        snackbar.addAlert(t('Your session has expired, please login again'), Severity.WARNING);
        authContext.logout();
        return;
      }
      if (graphError.message) {
        snackbar.addAlert(graphError.message, Severity.ERROR);
        return;
      }
      snackbar.addAlert(t('We encountered an application error: {{data}}', { data: graphError.name }), Severity.ERROR);
    }
  }
}

export async function hash(message: string): Promise<string> {
  const utf8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

export function hexToInt(hex: string): number {
  return parseInt(hex.substring(0, 8), 16);
}

export function sortDateString(a: string, b: string): number {
  return new Date(b).getTime() - new Date(a).getTime();
}

export function removeElement<T extends Record<string, any>, K extends string>(object: T, key: K): Omit<T, K> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...rest } = object;
  return rest;
}

export function getMaxColumns(arr: any[][]): number {
  let maxColumns = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].length > maxColumns) {
      maxColumns = arr[i].length;
    }
  }
  return maxColumns;
}

export function generateArrayOfNumbers(value: number): number[] {
  const arr = [];
  for (let i = 1; i <= value; i++) {
    arr.push(i);
  }
  return arr;
}
