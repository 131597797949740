import type { TFunction } from 'i18next';
import { LocationReference } from './location';
import { Sensor } from './sensor';

export enum ConnectionType {
  CHARP = 'charp',
  ADDON = 'addon',
  STANDALONE = 'standalone',
}

export const connectionTypes: { [key in ConnectionType]?: string } = {
  [ConnectionType.CHARP]: 'CHARP',
  [ConnectionType.STANDALONE]: 'Standalone',
};

export enum DeviceRoleTypes {
  MINI = 'mini device',
  MAXI = 'maxi device',
  MINI_GATEWAY = 'mini gateway',
  MAXI_GATEWAY = 'maxi gateway',
}
export interface DeviceMetadata {
  label: string;
  roles: DeviceRoleTypes[];
}

export enum CharpDeviceTypes {
  XTEL = 'xtel',
  ELSYS = 'elsys',
  TALKPOOL = 'talkpool',
  WATTECO = 'watteco',
  ABEEWAY = 'abeeway',
  COMPULAB = 'compulab',
  KERLINK = 'kerlink',
}

export const charpDeviceTypes: { [key in CharpDeviceTypes]: DeviceMetadata } = {
  [CharpDeviceTypes.XTEL]: { label: 'Xtel', roles: [DeviceRoleTypes.MINI, DeviceRoleTypes.MINI_GATEWAY] },
  [CharpDeviceTypes.ELSYS]: { label: 'Elsys', roles: [DeviceRoleTypes.MAXI] },
  [CharpDeviceTypes.TALKPOOL]: { label: 'Talkpool', roles: [DeviceRoleTypes.MAXI] },
  [CharpDeviceTypes.WATTECO]: { label: 'Watteco', roles: [DeviceRoleTypes.MAXI] },
  [CharpDeviceTypes.ABEEWAY]: { label: 'Abeeway', roles: [DeviceRoleTypes.MAXI] },
  [CharpDeviceTypes.COMPULAB]: { label: 'Compulab', roles: [DeviceRoleTypes.MINI_GATEWAY] },
  [CharpDeviceTypes.KERLINK]: { label: 'Kerlink', roles: [DeviceRoleTypes.MAXI_GATEWAY] },
};

export enum AddonDeviceTypes {
  VERSASENSE = 'versasense',
}

export const addonDeviceTypes: { [key in AddonDeviceTypes]: string } = {
  [AddonDeviceTypes.VERSASENSE]: 'VersaSense',
};

export enum StandaloneDeviceTypes {
  ELSEC = 'elsec',
  HANWELL = 'hanwell',
  LASCAR_ELECTRONICS = 'lascar_electronics',
  OTHER = 'other',
}

export const standaloneDeviceTypes: { [key in StandaloneDeviceTypes]: string } = {
  [StandaloneDeviceTypes.ELSEC]: 'Elsec',
  [StandaloneDeviceTypes.HANWELL]: 'Hanwell',
  [StandaloneDeviceTypes.LASCAR_ELECTRONICS]: 'Lascar Electronics',
  [StandaloneDeviceTypes.OTHER]: 'Other',
};

export enum ElsecDeviceModels {
  ELSEC_765 = '765',
}

export const elsecDeviceModels: { [key in ElsecDeviceModels]: string } = {
  [ElsecDeviceModels.ELSEC_765]: '765',
};

export enum ElsysDeviceModels {
  ERS = 'ERS',
}

export const elsysDeviceModels: { [key in ElsysDeviceModels]: string } = {
  [ElsysDeviceModels.ERS]: 'ERS',
};

export enum HanwellDeviceModels {
  RL45 = 'rl45',
}

export const hanwellDeviceModels: { [key in HanwellDeviceModels]: string } = {
  [HanwellDeviceModels.RL45]: 'RL45xx',
};

export enum LascarElectronicsDeviceModels {
  EASYLOG_EL_USB_2_PLUS = 'easylog_el-usb-2+',
  EASYLOG_EL_USB_TC = 'easylog_el-usb-tc',
}

export const lascarElectronicsDeviceModels: { [key in LascarElectronicsDeviceModels]: string } = {
  [LascarElectronicsDeviceModels.EASYLOG_EL_USB_2_PLUS]: 'EasyLog EL-USB-2+',
  [LascarElectronicsDeviceModels.EASYLOG_EL_USB_TC]: 'EasyLog EL-USB-TC',
};

export type DeviceType = CharpDeviceTypes | AddonDeviceTypes | StandaloneDeviceTypes;
export type DeviceModel =
  | ElsecDeviceModels
  | ElsysDeviceModels
  | HanwellDeviceModels
  | LascarElectronicsDeviceModels
  | string;

export interface Device {
  id: string;
  name: string;
  connection: ConnectionType;
  type: DeviceType;
  model?: DeviceModel;
  locations: LocationReference[];
  battery?: number;
  sensors?: Sensor[];
  updatedAt: string;
  createdAt: string;
}

const nameTooShortMessage = (t: TFunction) => t('A device needs a name');
const nameTooLongMessage = (t: TFunction) => t('A device name cannot exceed 255 characters');
const locationTooShortMessage = (t: TFunction) => t('A device location should have a name');
const locationTooLongMessage = (t: TFunction) => t('A device location cannot exceed 255 characters');

const validateLength =
  (min: number, max: number, tooShortMessage: (t: TFunction) => string, tooLongMessage: (t: TFunction) => string) =>
  (value: string, t: TFunction): string | undefined =>
    value.length < min ? tooShortMessage(t) : value.length > max ? tooLongMessage(t) : undefined;

export const validateName = validateLength(1, 255, nameTooShortMessage, nameTooLongMessage);

const _validateLocation = validateLength(1, 255, locationTooShortMessage, locationTooLongMessage);

export const validateLocation = (location: string | null, t: TFunction): string | undefined =>
  location !== null ? _validateLocation(location, t) : undefined;
